$position-card-header-height: 80px;
$position-card-content-height: 240px;
$position-card-footer-height: 80px;
$position-card-header-padding: 15px 14px 25px;
$position-card-footer-padding: 25px 15px 15px 20px;

$position-card-font-size-big: 16px;
$position-card-font-size-small: 14px;
$position-card-letter-spacing: .05em;

$position-card-vertical-distance: 25px;

$position-card-box-shadow-range: 30px;
$position-card-border-radius: 20px;

$position-card-line-height: 18px;