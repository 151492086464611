$application-table-border: 1px solid $color-black;
$application-table-separator-height: 15px;

$application-table-cell-width: 20%;
$application-table-cell-padding-horizontal: 5px;
$application-table-cell-padding-vertical: 10px;

$application-table-lead-padding-left: 20px;
$application-table-lead-border-radius: 20px;

$application-table-button-padding-horizontal: 5px;
$application-table-button-padding-vertical: 30px;

$application-table-mobile-header-padding: 15px;
$application-table-mobile-list-padding-left: 0;

$application-table-mobile-list-item-padding-top: 5px;
$application-table-mobile-list-item-padding-bottom: 5px;
$application-table-mobile-list-item-padding-left: 15px;

$application-table-mobile-button-padding: 10px;
$application-table-mobile-button-width: 80%;
$application-table-mobile-button-margin: 0 auto 10px;