.application-results {

	padding-top: 40px;

	&--result-container {
		padding-bottom: 20px;
	}

	&__label {
		padding-bottom: 15px;

		&--bold {
			font-weight: 700;
			letter-spacing: 1px;
		}

		&--success {
			color: $color-pistachio;
			font-weight: 700;
		}

		&--failed {
			color: $color-burnt-sienna;
			font-weight: 700;
		}

		&--failed-result {
			color: $color-burnt-sienna;
		}
	}

	&__separator {
		border-top: 1px solid $color-black;
		padding-bottom: 15px;
	}

	&__cancel {
		margin-top: 15px;
		margin-bottom: 30px;
	}
}