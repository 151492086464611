.applications-table {
  border-collapse: collapse;
  table-layout: fixed;

  &__row {
    &--top {
      .applications-table__cell:not(:first-of-type) {
        border-top: $application-table-border;
      }

      .applications-table__cell:last-of-type {
        border-bottom: $application-table-border;
      }
    }

    &--bottom {
      .applications-table__cell {
        border-bottom: $application-table-border;
        font-style: italic;

        &--green {
          color: $color-application-state-success;
        }

        &--red {
          color: $color-application-state-fail;
        }
      }
    }
  }

  &__separator {
    height: $application-table-separator-height;
  }

  &__cell {
    width: $application-table-cell-width;
    padding: $application-table-cell-padding-horizontal $application-table-cell-padding-vertical;
    border-right: $application-table-border;
    border-left: $application-table-border;

    &--lead {
      padding-left: $application-table-lead-padding-left;
      border: none;
      border-top: 1px solid $color-primary;
      border-bottom-left-radius: $application-table-lead-border-radius;
      background-color: $color-primary;
      color: $color-white;
    }

    &--heading {
      background-color: $color-gallery;
    }
  }

  &__button {
    @include border-radius(0);
    padding: $application-table-button-padding-horizontal $application-table-button-padding-vertical;
    letter-spacing: 0;
    text-transform: none;
    font-weight: bold;
  }

  &--mobile {
    border: 1px solid $color-black;
    margin-bottom: 15px;

    &-header {
      color: $color-white;
      background-color: $color-primary;
      border-bottom-left-radius: $application-table-lead-border-radius;
      padding: $application-table-mobile-header-padding;
    }

    &-lead {
      text-transform: uppercase;
    }

    &-details {
      font-style: italic;
      background-color: $color-gallery;

      &-green {
        color: $color-application-state-success;
      }

      &-red {
        color: $color-application-state-fail;
      }
    }

    &-text-right {
      text-align: right;
    }

    &-list {
      list-style-type: none;
      padding-left: $application-table-mobile-list-padding-left;
    }

    &-list-item {
      padding-top: $application-table-mobile-list-item-padding-top;
      padding-bottom: $application-table-mobile-list-item-padding-bottom;
      padding-left: $application-table-mobile-list-item-padding-left;
    }

    &-button {
      padding: $application-table-mobile-button-padding;
      width: $application-table-mobile-button-width;
      margin: $application-table-mobile-button-margin;
      text-transform: none;
    }

  }
}