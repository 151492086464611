$menu-item-common-height: 40px;
$submenu-item-common-height: 30px;

$menu-common-padding: $menu-item-common-height;

$menu-item-common-line-height: 26px;
$submenu-item-common-line-height: 22px;
$submenu-item-extra-offset: 0;

.navigation {
  margin: 0;
  padding: 0;
  color: $color-white;
  background-color: transparent;

  &__icon {

    top: 0;
    left: 0;
    position: absolute;
    text-align: center;
    width: $menu-common-padding;
    line-height: $menu-item-common-height;
  }

  &__item {
    @include transition(background-color .3s);
    color: $color-white;
    // TODO: background-color: $color-common;
    border-color: $color-white;
  }

  &__submenu {

    padding: 0;
    display: none;
    list-style: none;
    color: $color-white;
    // TODO: background-color: $color-common;
    border-color: $color-white;
  }

  &__link {

    @include interaction-link(false) {

      width: 100%;
      font-size: 14px;
      font-weight: normal;
      line-height: $menu-item-common-line-height;
      padding: ($menu-item-common-height - $menu-item-common-line-height) / 2 $menu-common-padding;

    }

    @include transition(background-color .3s);

    position: relative;

  }

  &__sublink {

    @include interaction-link(false) {

      width: 100%;
      font-size: 12px;
      font-weight: normal;
      line-height: $submenu-item-common-line-height;
      padding: ($submenu-item-common-height - $submenu-item-common-line-height) / 2 $menu-common-padding ($submenu-item-common-height - $submenu-item-common-line-height) / 2 ($menu-common-padding + $submenu-item-extra-offset);

    }

    @include transition(background-color .3s);

    position: relative;

  }

  &__list {
    display: table;
  }

  &__element {
    display: table-cell;
    text-align: center;
    vertical-align: top;
    padding: 5px 20px;
    position: relative;
  }

  &__element:after {
    display: none;
    content: " ";
    width: 80%;
    height: 1px;
    position: absolute;
    left: 10%;
  }

  &__element:hover:after {
    display: block;
    background-color: $color-black;
  }

  &__element--active {
    color: $color-mine-shaft;
  }

  &__element--active:after {
    display: block;
    background-color: $color-mine-shaft;
  }
}