.menu {
  margin-bottom: -1px;
  background-color: $color-white;

  .button {
    &:disabled {
      color: $color-black;
    }
  }

  &__header {
    background-color: $color-scarpa-flow;
    border: 1px solid $color-white;
    color: $color-white;
    position: relative;
    line-height: 24px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    display: block;
    z-index: 1;
  }

  &__container {
    background-color: $color-scarpa-flow;
    border: 1px solid $color-white;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    &--children {
      padding-left: 50px;
      padding-top: 10px;
      border-color: $color-malibu $color-malibu $color-malibu $color-scarpa-flow;
    }
  }

  &__hidden {
    display: none;
    max-width: 0;
    max-height: 0;
  }

}

