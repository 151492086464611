.paging {
  margin-top: $paging-margin-top;
  font-weight: $font-weight-semibold;
  text-align: center;

  &__page {
    border: none;
    background: none;
    background-color: transparent;
    padding: $paging-page-padding-horizontal $paging-page-padding-vertical;

    &--active {
      background-color: $color-primary;
      color: $color-white;
      border-radius: $paging-active-page-border-radius;
    }
  }

  &__container {
    display: inline-block;
    padding: $paging-container-padding-horizontal $paging-container-padding-vertical;
  }
}