.vi {
  &--tooltip {
    color: $color-oslo-gray;

    &-inverse {
      @include hover() {
        &:before {
          content: $vi--question-mark--circle;
        }
      }
    }
  }

  &--checkbox {
    color: $color-oslo-gray;
  }

  &--alert {
    color: $color-guardsman-red;
  }
}