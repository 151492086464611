.notification-item {
  padding: $notification-item-padding;
  border: $notification-item-border;

  &__title {
    background-color: $color-white;
    padding: $notification-item-title-padding;

    &:hover {
      background-color: $color-alto;
    }

    &:focus {
      background-color: $color-alto;
    }
  }

  &__title-text {
    color: $color-scarpa-flow;
  }

  &__actions {
    padding: $notification-item-actions-padding;
    background-color: $color-white;
    border-left: $notification-item-actions-border-left;
  }

  &__actions-content {
    padding-top: $notification-item-actions-content-padding-top;
  }

  &__content {
    @include box-shadow(inset 0 -8px 39px -6px $color-alto);
    border-left: $notification-item-content-border;
    border-bottom: $notification-item-content-border;
    border-right: $notification-item-content-border;
  }

  &__content-text {
    text-align: left;
  }

  &__icon {
    color: $color-scarpa-flow;
    font-size: $notification-item-icon-font-size;
    padding: $notification-item-icon-padding;

    &--active {
      color: $color-primary;
    }

    &--new {
      background-color: $color-primary;
      height: $notification-item-icon-height;
      width: $notification-item-icon-width;
      position: absolute;
      border-radius: $notification-item-icon-border-radius;
      top: $notification-item-icon-top;
      right: $notification-item-icon-right;
    }
  }

  &__button {
    background-color: $notification-item-button-background-color;
    border: $notification-item-button-border;
  }
}