$modal-spinner-size: 2.5rem;
$modal-content-padding: 36px;

.overlay {

  @include transition(background-color .5s);

  top: 0;
  width: 0;
  height: 0;
  position: fixed;
  background-color: transparent;
  z-index: $modal-backdrop-z-index-common;

  &--show {
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  &--animate {
    background-color: $color-black-50;
  }
}

.modal {

  @extend .spring--sm;

  @include box-shadow(0 3px 9px $color-black-50);

  &--lg {
    @extend .spring--md;
  }

  opacity: 1;
  outline: 0;
  display: none;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  color: $color-white;
  background-color: $color-white;
  border: 1px solid $color-black-20;

  &__close {

    @include interaction() {

      @include transition(color .3s);

      outline: 0;
      color: $color-mine-shaft;
    }

    @include xs() {

      @include transition(background-color .5s);

      &--mobile-scrolled {

        @include interaction() {

          @include transition(background-color .3s);

          background-color: $color-mine-shaft-75;
          color: $color-white;
        }

        background-color: $color-black-50;
        top: $gutter-size-md;
        color: $color-white;
        position: fixed;
        right: 1px;
      }
    }

    color: $color-mine-shaft-25;
    background-color: transparent;
    padding: 8px 10px;
    position: absolute;
    font-size: 16px;
    border: none;
    bottom: auto;
    right: 20px;
    left: auto;
    top: 20px;
  }

  &__container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: none;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    padding: $gutter-size-md 0;

    &--show {
      display: block;
      z-index: $modal-content-z-index-common;
    }
  }

  &__content {
    position: relative;
    color: $color-mine-shaft;
    background-color: $color-white;
    padding: $modal-content-padding;
  }

  &--show {
    display: block;
  }

  &__footer {

    padding-top: $gutter-size-sm;
  }

  &__actions {
    text-align: center;
  }

  &__loader {

    @include transition(all .25s ease-in-out);

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: wait;
    height: 100%;
    display: none;
    overflow: visible;
    position: absolute;
    text-align: center;
    color: $color-scarpa-flow;
    font-size: $modal-spinner-size;
    background-color: $color-gallery-75;

    &--loading {
      @include transition(none);

      display: block;
    }

    &-spinner {
      left: 0;
      right: 0;
      top: 50%;
      bottom: 50%;
      display: block;
      margin: -.2rem;
      position: absolute;

      &-icon {
        display: inline-block;
        margin-top: $modal-spinner-size / -2;
      }
    }
  }
}

.js-modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}