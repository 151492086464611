.accordion {
  margin-bottom: -1px;
  background-color: $color-white;

  &__button {
    @include transition(background-color .3s);

    padding: $gutter-size-xs $gutter-size-md $gutter-size-xs 40px;
    background-color: $color-scarpa-flow;
    border: 1px solid $color-white;
    color: $color-white;
    position: relative;
    line-height: 24px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    display: block;
    width: 100%;
    z-index: 1;

    &:hover,
    &--active {
      background-color: $color-mine-shaft;
      outline: 0;
      z-index: 2;
    }

    &-sign {

      background-color: $color-mine-shaft;

      &--closed {
        display: inline-block;
      }

      &--opened {
        display: none;
      }

    }

    &-icon {
      color: $color-white;
      position: absolute;
      text-align: center;
      line-height: 42px;
      width: 40px;
      right: auto;
      bottom: 0;
      left: 0;
      top: 0;
    }
  }

  &__slide-down {
    display: none;

    //background-color: $color-gallery-50;
    //border: 1px solid $color-alto;
    padding: $gutter-size-xs $gutter-size-md;
    border-top: none;
  }

  &__slide-down--display {
    display: block;
  }

  &__button--imgup {
    max-width: 5%;
    max-height: 5%;
    float: left;
  }

  &__button--imgright {
    max-width: 5%;
    max-height: 5%;
    float: left;
    transform: rotate(90deg);
  }

  &__button--text {
    margin: auto;
  }
}

.accordion__button--active {
  .accordion__button {
    &-sign {

      &--closed {
        display: none;
      }

      &--opened {
        display: inline-block;
      }

    }
  }
}

