.dropdown {

  &--open {

    background-color: $color-gallery;
  }

  &__menu {

    overflow-x: hidden;

    &:before {

      content: " ";
      width: auto;
      left: -100px;
      height: 40px;
      right: -100px;
      display: block;
      position: absolute;
    }

    &-icon {

      right: auto;
      display: block;
      position: absolute;
      font-size: 1.25rem;
      width: $dropdown-menu-item-icon-width;
      left: $dropdown-menu-item-vertical-padding;
      top: $dropdown-menu-item-horizontal-padding;
      bottom: $dropdown-menu-item-horizontal-padding;
    }

    &-item {

      position: relative;
      text-align: center;

      &--action {

        @include overflow-nowrap();

        @include interaction() {

          color: $color-dropdown-text-action;
          background-color: $color-dropdown-background-action;
        }

        display: block;
        font-size: 1rem;
        text-align: center;
        line-height: $dropdown-menu-item-line-height;
        padding: $dropdown-menu-item-horizontal-padding $dropdown-menu-item-vertical-padding $dropdown-menu-item-horizontal-padding ($dropdown-menu-item-vertical-padding * 2 + $dropdown-menu-item-icon-width);

      }
    }

    &--open {

      &:before {

        @include box-shadow(0 15px 40px -19px $color-black-20 inset);
      }
    }
  }

  &__caret {

    &--show {

      display: none;
    }
  }
}