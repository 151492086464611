@mixin no-gutters-classes() {
  @include no-gutters-margin();

  @each $screen in $screen-selectors {
    @include media($screen, min) {
      @include no-gutters-margin(#{$screen}--);
    }
  }
}

@mixin no-gutters-margin($device: "") {
  &--#{$device}all {
    margin: 0 !important;
  }

  &--#{$device}top {
    margin-top: 0 !important;
  }

  &--#{$device}bottom {
    margin-bottom: 0 !important;
  }

  &--#{$device}left {
    margin-left: 0 !important;
  }

  &--#{$device}right {
    margin-right: 0 !important;
  }
}
