.nudge {

  @include transition(all .75s);

  width: 100%;
  height: 0;
  opacity: 0;
  color: $color-white;

  &--top {
    background-color: $color-mine-shaft;
  }

  &--bottom {
    position: fixed;
    bottom: 0;
    background-color: $color-mine-shaft-75;
  }

  &--footer {
    background-color: $color-mine-shaft-75;
  }

  &__container {
    padding: 32px 96px;
  }

  &--display {
    border-top: 3px solid $color-mine-shaft;
    border-bottom: 3px solid $color-mine-shaft;
    opacity: 1;
    height: auto;
  }

}