.tooltip {
  display: block;
  position: fixed;
  z-index: $tooltip-z-index-common;

  &__content {
    display: block;
    padding: $gutter-size-xs $gutter-size-sm;
    max-width: 300px;
    font-size: 14px;
    line-height: 1;
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
  }

  &__arrow {
    position: fixed;
    display: block;
    z-index: -1;
    width: 0;
    height: 0;
  }
}