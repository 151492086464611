.flag {

  &__hoverbutton {
    width: 40px;
    height: 25px;
    background-color: $color-white;
    border-color: $color-black;
    border-width: 2px;
    color: $color-black;
    text-align: center;
  }

  &__button {
    padding: 5px;

    &--container {
      @include interaction() {
        cursor: pointer;
        font-size: 25px;
      }

      display: inline-block;
      font-size: 20px;
    }
  }

  &__container {
    @include interaction() {
      cursor: pointer;
    }
    z-index: 50;
    position: fixed;
    background-color: $color-white;
    border: solid;
    border-width: 2px;
    // TODO: border-color: $color-common;
    padding: 5px;
    display: block;
    text-align: left;
  }
}