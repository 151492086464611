body {
  &.body {
    .mm-dropdown {
      top: 0 !important;
      right: 0 !important;
      background-color: $color-white;
      width: 260px;
    }

    .mm-listview > li:not(.mm-divider):after {
      border: none;
    }

    .mm-panels > .mm-panel > .mm-listview:first-child,
    .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
      margin-bottom: -10px;
    }

    .mm-menu .mm-btn:after,
    .mm-menu .mm-btn:before {
      border-color: $color-primary;
    }

    .mm-navbar {
      border: none;
    }

    .mm-navbar .mm-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $color-black;
      font-size: 12px;
      font-weight: $font-weight-semibold;
    }

    .mm-menu .mm-listview > li .mm-next:after {
      border-color: $color-primary;
    }
  }
}
