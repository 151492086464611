.subheading {
  margin-bottom: 8px;

  &__label {
    font-size: 1.1rem;
    font-weight: bolder;
  }

  &__value {
    font-size: 1rem;
  }
}