@import "../../../node_modules/tiny-slider/src/tiny-slider.scss";

.tns-controls [aria-controls] {
  font-size: 15px;
  margin: 0 5px;
  padding: 0 1em;
  height: 2.5em;
  color: $color-black;
  background: $color-malibu;
  border-radius: 3px;
}

.tns-inner .tns-item {
	font-size: 0;

	* {
		font-size: 1rem;
	}
}

.tns-outer {
	@include sm(min) {
		padding-bottom: 0 !important;
  	}

	padding-bottom: 35px !important;
}

.tns-carousel {
	background-color: $color-alto;
}

.tns-youtube-video {
	background-color: $color-alto;

	&,
	iframe {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
	}

	iframe {
	    height: 100%;
	    margin: 0 auto;
	    max-width: 100%;
	}
}

.tns-video {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	background-color: $color-alto;

	video {
		width: auto;
	    height: 100%;
	    margin: 0 auto;
	    display: block;
	    max-width: 100%;
	}
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;

    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
}

.tns-controls {
  text-align: center;
}

.tns-slider {
  	@include md(min) {
  		min-height: 500px;
	}

  	min-height: 400px;
}

.tns-control-button {
  position: absolute;
  width: 40px;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  font-size: 16px;
  background-color: $color-primary;
  text-align: center;
  cursor: pointer;
  color: $color-white;

  &-previous {
    left: 0;
  }

  &-next {
    right: 0;
  }
}

.tns-item {
	@include md(min) {
  		min-height: 500px;
	}

  min-height: 400px;
  text-align: center;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > img {
    width: 100%;
  }
}

#carousel-thumbnails {
  position: absolute;
  left: 50%;
  height: 35px;
  transform: translateX(-50%);
}

.tns-nav-item.tns-nav-active > div > div {
  background-color: $color-secondary;
  position: absolute;
  left: 4px;
  top: 4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

.tns-nav-item {
  display: inline-block;
  background-color: $color-primary;
  margin-right: -4px;
  cursor: pointer;
  width: 40px;
  height: 35px;
  line-height: 45px;
  text-align: center;
}

.inner {
  position: relative;
  display: inline-block;
  text-align: center;
  background-color: $color-white;
  width: 16px;
  height: 16px;
  border-radius: 30px;
}

.tns-visually-hidden {
  display: none;
}

.carousel-hud {
  margin-top: -35px;
  position: relative;
}