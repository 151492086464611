.megaselect {

  &__loader {

    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    height: 34px;
    padding: 7px;
    position: absolute;
  }

  &__results {

    display: none;
    position: fixed;
    margin-top: -1px;
    overflow-y: auto;
    background-color: $color-white;
    border: 1px solid $color-oslo-gray;
    z-index: $megaselect-z-index-common;
  }

  &__list {

    margin: 0;
    padding: 0;
    list-style: none;

    &-item {

      @include interaction() {
        background-color: $color-alto;
      }

      border: none;
      width: 100%;
      display: block;
      padding: 6px 12px;
      text-align: inherit;
      background-color: transparent;

      &--selected {
        background-color: $color-gallery;
      }
    }
  }
}