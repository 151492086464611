@import "../font-weights";

$font-path: "../fonts";

$font-family-monospace: Consolas, "Courier New", monospace;

$primary-font-name: "Veolia-TheSans";

$base-font-size-xs: 18px;
$base-font-size-sm: 18px;
$base-font-size-md: 18px;
$base-font-size-lg: 16px;

/*
  Font variable: Font family, fallback fonts
*/
$primary-font: $primary-font-name, sans-serif, Helvetica, Arial;

/*
  @Array of @Objects:
    1: Font file name
    2: Font type name
    3: Font style
    4: Font weight
*/
$primary-font-types: (("TheSans-Regular", "Veolia-TheSans-Regular", normal, $font-weight-regular),
        ("TheSans-Bold", "Veolia-TheSans-Bold", normal, $font-weight-bold));

/*
  @Object
    1: Font name
    2: Font folder name
    3: Font fallback
    4: Font types @Object
*/
$primary-font-wrapper: (($primary-font-name, "the-sans", $primary-font-types), );

/*
  @Array of @Objects:
*/
$font-families: $primary-font-wrapper;