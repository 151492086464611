$logigrid-background-color: $color-white;
$logigrid-outer-padding: $gutter-size-xs;
$logigrid-border-color: $color-alto;

$logigrid-loading-indicator-background-color: $color-white-75;
$logigrid-loading-indicator-font-size: $gutter-size-md;
// TODO: $logigrid-loading-indicator-color: $color-oslo-gray;

// TODO: $logigrid-header-filter-interaction-color: $color-oslo-gray;
$logigrid-header-background-color: $color-mine-shaft;
// TODO: $logigrid-header-filter-active-color: $color-danube;
$logigrid-header-text-color: $color-white;

// TODO: $logigrid-filter-background-color: $color-scarpa-flow;
$logigrid-filter-text-color: $color-white;

$logigrid-data-row-odd-background-color: $color-gallery-50;
$logigrid-data-row-hover-background-color: $color-gallery;
$logigrid-data-row-even-background-color: $color-white;

$logigrid-pagination-page-button-disabled-background-color: $color-gallery;
$logigrid-pagination-page-button-background-color: $color-white;
// TODO: $logigrid-pagination-page-button-disabled-color: $color-silver;
$logigrid-pagination-page-background-color: $color-white;
$logigrid-pagination-page-button-size: $gutter-size-md;
$logigrid-pagination-page-font-size: $gutter-size-sm;
$logigrid-pagination-page-border-color: $color-alto;

$logigrid-entry-count-font-size: 1.5 * $gutter-size-xs;
$logigrid-entry-count-color: $color-mine-shaft-75;

$logigrid-length-select-font-size: 1.5 * $gutter-size-xs;
$logigrid-length-select-color: $color-mine-shaft-75;