@mixin flush-padding($device: "") {
  &--#{$device}all {
    padding: 0 !important;
  }

  &--#{$device}top {
    padding-top: 0 !important;
  }

  &--#{$device}bottom {
    padding-bottom: 0 !important;
  }

  &--#{$device}left {
    padding-left: 0 !important;
  }

  &--#{$device}right {
    padding-right: 0 !important;
  }
}

@mixin flush-classes() {
  @include flush-padding();

  @each $screen in $screen-selectors {
    @include media($screen, only) {
      @include flush-padding(#{$screen}--);
    }
  }
}
