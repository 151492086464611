@include xs(max) {
  html {
    font-size: $base-font-size-xs;
  }
}

@include sm(only) {
  html {
    font-size: $base-font-size-sm;
  }
}

@include md(only) {
  html {
    font-size: $base-font-size-md;
  }
}

@include lg(min) {
  html {
    font-size: $base-font-size-lg;
  }
}