@mixin interaction($extended: false) {
  @if $extended {

    &,
    &:hover,
    &:focus {
      @content;
    }

  } @else {

    &:hover,
    &:focus {
      @content;
    }

  }
}

@mixin interaction-link($is-interaction: true) {

  @if $is-interaction {

    &:focus,
    &:hover,
    &:active {
      @content;
    }

  } @else {

    &,
    &:visited {
      @content;
    }

  }
}

@mixin hover() {
  &:hover,
  &.hover {
    @content;
  }
}

@mixin focus() {
  &:focus,
  &.focus {
    @content;
  }
}
