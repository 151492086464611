p {

  margin: 15px 0;
}

@include sm(max) {

  p {

    margin: 10px 0;
  }
}