@mixin gutters-classes() {
  @each $gutter-selector, $gutter-value in $gutter-selectors {
    @include gutters-margin("", $gutter-value, $gutter-selector);

    @each $screen in $screen-selectors {
      @include media($screen, min) {
        @include gutters-margin(#{$screen}--, $gutter-value, $gutter-selector);
      }
    }
  }
}

@mixin gutters-margin($device, $gutter-value, $gutter-selector) {
  &--#{$device}gutter-#{$gutter-selector}--all {
    margin: $gutter-value !important;
  }

  &--#{$device}gutter-#{$gutter-selector}--top {
    margin-top: $gutter-value !important;
  }

  &--#{$device}gutter-#{$gutter-selector}--bottom {
    margin-bottom: $gutter-value !important;
  }

  &--#{$device}gutter-#{$gutter-selector}--left {
    margin-left: $gutter-value !important;
  }

  &--#{$device}gutter-#{$gutter-selector}--right {
    margin-right: $gutter-value !important;
  }
}

@mixin common-gutter($property, $value-divider) {
  @each $media-selector in $screen-selectors {
    &--#{$media-selector} {
      @include media($media-selector, min) {
        &--gutter {
          @each $gutter-selector, $gutter-value in $gutter-selectors {
            &-#{$gutter-selector} {
              #{$property}-left: $gutter-value / $value-divider;
              #{$property}-right: $gutter-value / $value-divider;
            }
          }
        }
      }
    }
  }
}

@mixin grid-gutters() {
  @include common-gutter(margin, -2);
}

@mixin grid-item-gutters() {
  @include common-gutter(padding, 2);
}

@mixin grid-item-connectors() {
  @each $media-selector in $screen-selectors {
    &--#{$media-selector} {
      @include media($media-selector, min) {
        &--gutter {
          @each $gutter-selector, $gutter-value in $gutter-selectors {
            &-#{$gutter-selector} {
              width: $gutter-value;
            }
          }
        }
      }
    }
  }
}
