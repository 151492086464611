.loader {
  display: block;
  text-align: center;
  width: 160px;
  margin: 0 auto;
  height: 20px;
}

.loading {

  &__dots {

    /*TODO: fill: $color-solitude;*/
    fill-opacity: 1;
/*
    &--animating {

      fill: $color-boston-blue;
    }*/
  }
}