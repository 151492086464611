$notification-item-padding: 1px;
$notification-item-border: 1px solid $color-black;

$notification-item-title-padding: 13px;

$notification-item-actions-padding: 15px;
$notification-item-actions-border-left: 1px solid $color-black;
$notification-item-actions-content-padding-top: 25px;

$notification-item-content-border: 1px solid $color-black;

$notification-item-icon-font-size: 1.5em;
$notification-item-icon-padding: 10px;

$notification-item-button-background-color: transparent;
$notification-item-button-border: none;

$notification-item-icon-width: 8px;
$notification-item-icon-height: 8px;
$notification-item-icon-border-radius: 10px;
$notification-item-icon-top: 20%;
$notification-item-icon-right: 51%;
