.list {
  &--unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    &--unstyled {
      margin: 0;
      padding: 0;
      display: block;
    }
  }
}