$hero-height-lg: 397px;
$hero-height-md: 300px;
$hero-height-sm: 200px;

$hero-xs-height-lg: 100px;
$hero-xs-height-md: 100px;
$hero-xs-height-sm: 100px;

$hero-content-min-width-lg: 50%;

$hero-content-panel-offset-lg: 60px;
$hero-content-panel-offset-md: 60px;
$hero-content-panel-offset-sm: 60px;

$hero-content-border-top-right-radius: 20px;
$hero-content-panel-vertical-padding: 20px;