$spec-box-max-box-height: 180px;
$spec-box-padding-horizontal: 0;
$spec-box-padding-vertical: .75rem;

$spec-box-heading-padding-top: 12px;
$spec-box-heading-padding-bottom: 8px;
$spec-box-heading-padding-vertical: 0;
$spec-box-heading-font-size: 1.1rem;

$spec-box-content-padding-bottom: .75rem;
$spec-box-content-font-size: 1rem;

$spec-box-toggle-height: 30px;
$spec-box-toggle-padding-horizontal: 6px;
$spec-box-toggle-padding-vertical: 12px;
$spec-box-toggle-font-size: 1rem;
