$mobile-menu-bg-color: #E7E7E7;

.mm-page,
.mm-animation-reset {

  .content {
    @include fade-in;
  }

  .footer {
    @include fade-in;
  }
}

.mobilemenu {

  &__toggle {

    @include transition(background-color .3s);

    outline: 0;
    border: none;
    font-size: 28px;
    background: none;
    padding: $gutter-size-xs $gutter-size-sm;
    padding-top: 30px;
  }

  &__nav {
    &-icon {

      width: 30px;
      font-size: 18px;
      vertical-align: middle;
      border-bottom: none;

      &--right {
        text-align: right;
        margin-bottom: 15px !important;
        padding-top: 15px !important;
        padding-right: 15px !important;
      }

      &--left {
        padding-right: 56px;
        padding-left: 10px;
      }

      &--left-notification {
        padding-right: 22px;
        padding-left: 10px;
      }

      &--right-notification {
        padding-right: 0;
        padding-left: 16px;
      }

      &--bigger {
        font-size: 44px;
        padding-right: 40px;
        padding-left: 0;
      }

      &--big {
        font-size: 30px;
        padding-right: 31px;
        padding-left: 0;
      }

      &--medium {
        font-size: 30px;
        padding-right: 31px;
        padding-left: 9px;
      }
    }

    &-text {
      font-weight: bold;
      font-size: 15px;
    }
  }

  &__li {
    margin-bottom: 5px;

    &:hover {
      background-color: $color-gallery;
    }
  }

  &__badge {
    padding: .25em .6em;
    margin-left: 3px;
    border-radius: 10rem;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: $color-primary;
    color: $color-white;
  }

  &__img {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid $color-primary;
    display: inline-block;
    padding-left: 10px;
    margin-right: 27px;
  }

  &__user {
    display: inline-block;
    bottom: 25px;
    position: absolute;

    &--username {
      padding-right: 20px;
    }
  }

  &__button {
    padding-left: 13px !important;
    text-align: left !important;
  }

  &__icon {
    &--veo-color {
      color: $color-primary;
    }

    &--green {
      color: $color-pistachio;
    }
  }

  &__profile {

    padding: 10px 10px 10px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
    display: block;
    margin: 0;

    &-picture {
      width: 40px;
      border: none;
      height: 40px;
      padding: 1px;
      overflow: hidden;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &--placeholder {
        font-size: 36px;
        display: block;
        margin: 1px;
      }

      img {
        @include opacity(0);

        width: 100%;
        height: 100%;
      }
    }

    &-name {

      font-size: 20px;
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__divider {
    margin-top: -1px !important;
    border: 1px solid $mobile-menu-bg-color !important;
  }

  &__logout {
    -webkit-tap-highlight-color: $color-white-50;
    tap-highlight-color: $color-white-50;
    background-color: transparent;
    padding: 10px 10px 10px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;
    color: inherit;
    display: block;
    border: none;
    width: 100%;
    margin: 0;
  }
}