@mixin user-select($value: auto) {
  -webkit-touch-callout: $value;
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin opacity($opacity: .5) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
  filter: alpha(opacity=$opacity);
}

@mixin pointer-events($pointer-events) {
  -moz-pointer-events: $pointer-events;
  -webkit-pointer-events: $pointer-events;
  -o-pointer-events: $pointer-events;
  -ms-pointer-events: $pointer-events;
  pointer-events: $pointer-events;
}

@mixin box-shadow($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow: $string;
  box-shadow: $string;
}

@mixin transform($string) {
  -webkit-transform: $string;
  -moz-transform: $string;
  -ms-transform: $string;
  -o-transform: $string;
}

// sass-lint:disable-all
@mixin horizontal-gradient($from, $to, $start-percent: 0%, $end-percent: 100%) {
  background-repeat: repeat-x;

  // Safari 5.1-6, Chrome 10+
  background-image: -webkit-linear-gradient(left, $from $start-percent, $to $end-percent);

  // Opera 12
  background-image: -o-linear-gradient(left, $from $start-percent, $to $end-percent);

  // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-image: linear-gradient(to right, $from $start-percent, $to $end-percent);

  // IE9 and down
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($from)}', endColorstr='#{ie-hex-str($to)}', GradientType=1);
}

@mixin vertical-gradient($from, $to, $start-percent: 0%, $end-percent: 100%) {
  background-repeat: repeat-x;

  // Safari 5.1-6, Chrome 10+
  background-image: -webkit-linear-gradient(top, $from $start-percent, $to $end-percent);

  // Opera 12
  background-image: -o-linear-gradient(top, $from $start-percent, $to $end-percent);

  // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-image: linear-gradient(to bottom, $from $start-percent, $to $end-percent);

  // IE9 and down
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($from)}', endColorstr='#{ie-hex-str($to)}', GradientType=0);
}

@mixin directional-gradient($from, $to, $deg: 45deg) {
  background-repeat: repeat-x;

  // Safari 5.1-6, Chrome 10+
  background-image: -webkit-linear-gradient($deg, $from, $to);

  // Opera 12
  background-image: -o-linear-gradient($deg, $from, $to);

  // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-image: linear-gradient($deg, $from, $to);
}
// sass-lint:enable-all
