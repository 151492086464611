/*COLOR DEFINITIONS START >>>*/
/*PRIMARY COLORS*/
$color-guardsman-red: #CE0000;

$color-cavern-pink: #DCB4AD;
$color-apricot: #F0806C;
$color-burnt-sienna: #E95F47;

$color-blaze-orange: #FF6600;

$color-candlelight: #FFD616;
$color-warning: #FFD02E;
$color-warning-dark: darken($color-warning, 5%);

$color-pistachio: #97BF0D;

$color-success: #7CC286;
$color-success-dark: darken($color-success, 5%);
$color-green-haze: #06A75A;
$color-fun-green: #047740;

$color-sail: #A1C8FA;
$color-morning-glory: #96D1E1;
$color-half-baked: #7ABCCE;
$color-malibu: #74AFF9;
$color-deep-cerulean: #0178B0;
$color-tory-blue: #134996;
$color-smalt: #012D9A;
$color-prussian-blue: #001F4B;
$color-catalina-blue: #0B3F79;
$color-malibu: #66CCFF;

/*MONOCHROME COLORS*/
$color-black: #000000;
$color-mine-shaft: #343434;
$color-scarpa-flow: #55555A;
$color-oslo-gray: #9C9E9F;
$color-alto: #D7D7D7;
$color-gallery: #EDEDED;
$color-white: #FFFFFF;

/*ALPHA MONOCHROME COLORS*/
$color-white-75: rgba($color-white, .75);
$color-white-50: rgba($color-white, .5);
$color-gallery-50: rgba($color-gallery, .5);
$color-gallery-75: rgba($color-gallery, .75);
$color-black-50: rgba($color-black, .5);
$color-black-20: rgba($color-black, .2);
$color-mine-shaft-25: rgba($color-mine-shaft, .25);
$color-mine-shaft-75: rgba($color-mine-shaft, .75);
/*<<< COLOR DEFINITIONS END*/