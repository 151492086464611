body {
  font-size: .875rem;
  color: $color-mine-shaft;
  font-family: $primary-font;
  line-height: $line-height-base;
  background-color: $color-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body {
  &--modal-open {
    overflow: hidden !important;
  }
}