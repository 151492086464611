/*LARGE SPRING SIZES*/
$spring-lg-min-screen-xs: 0;
$spring-lg-max-screen-xs: 100%;
$spring-lg-min-screen-sm: 512px;
$spring-lg-max-screen-sm: 720px;
$spring-lg-min-screen-md: 768px;
$spring-lg-max-screen-md: 900px;
$spring-lg-min-screen-lg: 1024px;
$spring-lg-max-screen-lg: 1152px;

/*MEDIUM SPRING SIZES*/
$spring-md-min-screen-xs: 0;
$spring-md-max-screen-xs: 100%;
$spring-md-min-screen-sm: 400px;
$spring-md-max-screen-sm: 680px;
$spring-md-min-screen-md: 600px;
$spring-md-max-screen-md: 800px;
$spring-md-min-screen-lg: 700px;
$spring-md-max-screen-lg: 960px;

/*SMALL SPRING SIZES*/
$spring-sm-min-screen-xs: 0;
$spring-sm-max-screen-xs: 100%;
$spring-sm-min-screen-sm: 480px;
$spring-sm-max-screen-sm: 480px;
$spring-sm-min-screen-md: 500px;
$spring-sm-max-screen-md: 500px;
$spring-sm-min-screen-lg: 540px;
$spring-sm-max-screen-lg: 540px;

$spring-sm: (xs, $spring-sm-min-screen-xs, $spring-sm-max-screen-xs),
        (sm, $spring-sm-min-screen-sm, $spring-sm-max-screen-sm),
        (md, $spring-sm-min-screen-md, $spring-sm-max-screen-md),
        (lg, $spring-sm-min-screen-lg, $spring-sm-max-screen-lg);

$spring-md: (xs, $spring-md-min-screen-xs, $spring-md-max-screen-xs),
        (sm, $spring-md-min-screen-sm, $spring-md-max-screen-sm),
        (md, $spring-md-min-screen-md, $spring-md-max-screen-md),
        (lg, $spring-md-min-screen-lg, $spring-md-max-screen-lg);

$spring-lg: (xs, $spring-lg-min-screen-xs, $spring-lg-max-screen-xs),
        (sm, $spring-lg-min-screen-sm, $spring-lg-max-screen-sm),
        (md, $spring-lg-min-screen-md, $spring-lg-max-screen-md),
        (lg, $spring-lg-min-screen-lg, $spring-lg-max-screen-lg);

$spring-sizes: (sm, $spring-sm), (md, $spring-md), (lg, $spring-lg);

$spring-base: $spring-lg;