.heading {
  margin: 0;
  width: 100%;
  display: block;
  font-family: $primary-font;
  line-height: $line-height-base;
  font-weight: $font-weight-regular;

  &--1 {
    font-size: 2rem;
  }

  &--2 {
    font-size: 1.875rem;
  }

  &--3 {
    font-size: 1.5rem;
  }

  &--4 {
    font-size: 1rem;
  }

  &--5 {
    font-size: .875rem;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--light {
    font-weight: $font-weight-light;
  }

  &--regular {
    font-weight: $font-weight-regular;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--center {
    text-align: center;
  }

  &--inline {
    display: inline-block;
    width: auto;
  }

  &--leading {
    margin-top: 0;
  }

  &--trailing {
    margin-bottom: 0;
  }

  &--no-gutter {
    margin: 0;
  }
}