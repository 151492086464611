.header {

  @include box-shadow(0 0 30px 0 $color-black-20);

  color: $color-header-main-text;
  background-color: $color-white;

  &--sticky {

    width: 100%;
    position: fixed;
    z-index: $header-z-index;
  }

  &__logo {

    float: left;
    font-size: 0;
    display: block;
    padding: $header-main-height - (2 * $header-main-logo-height);
    position: relative;
    height: $header-main-height;
    font-weight: $font-weight-bold;
    line-height: $header-main-height;

    &--mobile {
      @include sm(max) {
        padding: 25px 0;
        width: 45%;
      }
    }

    &--bigger {
      padding: 10px;
    }

    &-image {
      @include sm(max) {
        width: 100%;
        height: auto;
      }

      width: auto;
      height: 100%;
      display: block;
    }

    &-image-szvm {
      @include sm(max) {
        width: 100%;
        height: auto;
        padding-top: 15px;
      }

      width: auto;
      height: 100%;
      padding-top: 0;
      display: block;
    }

    &-image-fcsm {
      @include sm(max) {
        width: 100%;
        height: auto;
      }

      width: auto;
      height: 100%;
      padding-top: 0;
      display: block;
    }

    &-backdrop {
      top: 0;
      bottom: 0;
      left: -65px;
      right: -65px;
      position: absolute;
      background-size: cover;
      background-position: center center;
    }
  }

  &__badge {
    padding: .25em .6em;
    margin-left: 3px;
    border-radius: 10rem;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: $color-burnt-sienna;
    color: $color-white;

    &--notification {
      position: absolute;
      top: 25px;
      right: 198px;
      display: none;
    }
  }

  &__menu {

    @include clearfix();

    float: right;

    &-item {

      color: $color-header-menu-item;
      font-size: 1.5rem;
      text-align: center;
      min-width: $header-main-height;
      line-height: $header-menu-link-height;
      padding: ($header-main-height - $header-menu-link-height) / 2;

      &--big {

        display: block;
        padding: ($header-main-height - $header-menu-big-item-height) / 2;
      }
    }

    &-image {

      height: $header-menu-big-item-height;

      &--profile {

        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: $header-menu-big-item-height;
        border-radius: $header-menu-big-item-height;
        border: $header-menu-image-border-radius solid currentColor;
      }
    }

    &-link {

      display: block;
      font-size: $header-main-link-size;
      color: $color-scarpa-flow;
      font-weight: $font-weight-bold;
      line-height: $header-menu-link-height;
      letter-spacing: $common-link-letter-spacing;
    }

    &-group {

      @include interaction() {

        background-color: $color-gallery;
      }

      float: left;
    }

    &-notification {
      top: -4px;
      left: $header-menu-big-item-height - $header-menu-notification-size;
      right: auto;
      bottom: auto;
      font-size: 1.125rem;
      position: absolute;
      text-align: center;
      white-space: nowrap;
      line-height: 1.375rem;

      &-icon {
        position: relative;
        display: inline-block;

        & ~ & {
          margin-left: 2px;
        }

        &:before {
          @include border-radius($header-menu-notification-size);

          content: " ";
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
          position: absolute;
          background-color: $color-white;
        }

        & > * {
          display: block;
          position: relative;
        }
      }
    }
  }
}