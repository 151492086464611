.file-select {

  &__unit {
    margin: 5px 0;
  }

  &__file-name {
    @include overflow-nowrap();

    color: $color-oslo-gray;
    display: block;
  }

  &__button {
    cursor: pointer;

    &--add {
      color: $color-scarpa-flow;
    }

    &--text {
      text-decoration: underline;
    }

    &--remove {
      color: $color-oslo-gray;
      text-decoration: underline;
    }
  }
}