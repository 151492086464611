$banner-hero-height: 400px;
$banner-vertical-gutter: 50px;

.banner-position {
	@include sm(min) {
		margin: $banner-vertical-gutter 0;
  	}
  	
	text-align: left;

  &__upper {
  	padding: 40px 25px;
  	color: $color-white;
  	border-top-right-radius: 20px;
  	background-color: $color-primary;
  }

  &__lower {
  	padding: 30px 25px;
    background-color: $color-alto;
  }

  &__title {
  	max-height: 72px;
  	font-size: 22px;
  	line-height: 24px;
  	letter-spacing: .1em;
  	text-transform: uppercase;
  	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__description {
  	overflow: hidden;
  	max-height: 100px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    margin-bottom: 30px;
  }

  &__apply {
  	color: $color-primary;
    padding: 10px 20px;
    border: 1.2px solid $color-primary;
  }

  &__mobile-image {
  	@include sm(min) {
  		display: none;
  	}
  	
  	height: 200px;
    background-size: cover;
  	background-repeat: no-repeat;
    background-color: $color-alto;
  	background-position: center center;
  }
}
