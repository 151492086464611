.error-page {

  &__access-denied-icon {
    font-size: 7em;
    color: $color-error-page-access-denied-icon;
  }

  &__heading {
    color: $color-error-page-heading;
  }

}