.flexi-box {

  @include xs() {
    display: block;
  }

  border: none;
  width: 100%;
  display: table;
  border-spacing: 0;
  table-layout: auto;

  &--sm-clear {

    @include sm(max) {

      display: block;
    }
  }

  &__row {

    @include grid-gutters();

    display: block;
  }

  &__elem {

    @include grid-item-gutters();

    @include xs() {
      display: block;
      text-align: center;
    }

    display: table-cell;
    vertical-align: middle;

    &--max {
      width: 100%;
    }

    &--half {

      @include xs() {
        width: 100%;
      }

      width: 50%;
    }

    &--fifth {

      @include xs() {
        width: 100%;
      }

      width: 20%;
    }

    &--fourth {

      @include xs() {
        width: 25%;
      }

      &--three-fourth {

        @include xs() {
          width: 75%;
        }
      }

      width: 25%;
    }

    &--eight {

      @include xs() {
        width: 100%;
      }

      width: 12.5%;
    }

    &--sm-clear {

      @include sm(max) {
        display: block;
      }
    }

    &--min {

      @include xs() {
        width: 100%;
      }

      width: 1px;
    }

    &--nowrap {
      @include xs() {
        white-space: normal;
      }

      white-space: nowrap;
    }

    .form__input {
      margin-bottom: 0;
    }

    .form__input--distinct-xs {
      margin-bottom: $gutter-size-sm;
    }
  }
}