.section {
  @include clearfix;
  position: relative;

  &--gutter {
    margin: $gutter-size-lg 0;

    &--md {
      margin: $gutter-size-md 0;
    }

    &--sm {
      margin: $gutter-size-sm 0;
    }
  }

  &--ruled {
    border-bottom: 1px solid $color-gallery;
  }

  &--card {
    @include box-shadow(0 3px 9px rgba($color-black, .5));
  }
}

@include sm(max) {
  .section {
    &--gutter {
      margin: $gutter-size-md 0;

      &--sm {
        margin: $gutter-size-sm 0;
      }
    }
  }
}

@include xs() {
  .section {
    &--gutter {
      margin: $gutter-size-sm 0;

      &--sm {
        margin: $gutter-size-xs 0;
      }
    }
  }
}