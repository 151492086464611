.notification {

  padding: 30px;

  &--success {
    // TODO: background-color: $color-success;
    color: $color-white;
  }

  &--warning {
    // TODO: background-color: $color-warning;
    color: $color-white;
  }

  &--error {
    // TODO: background-color: $color-danger;
    color: $color-white;
  }
}