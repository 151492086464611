.icon {
  display: inline-block;

  &--square {
    display: block;
    position: relative;

    svg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }

  &--lg {
    width: 66px;
    height: 66px;
  }

  &--vam {
    vertical-align: middle;
  }

  &__placeholder {

    &--square {

      padding-bottom: 100%;
      display: block;
      width: 100%;
      height: 0;
    }
  }
}

.vi {
  &--spin {
    @include animation(rotate-loading 1.125s infinite linear);
  }
}