@mixin placeholder() {

  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin generate-form-design-checkbox-icon-shadow() {
  &,
  &:before {
    position: relative;
  }

  &:before {
    z-index: 1;
  }

  &:after {
    @include border-radius(nth($form-input-checkbox-icon-shadow-properties, 1));
    @include box-shadow(0 0 nth($form-input-checkbox-icon-shadow-properties, 2) 0 $color-black-50);

    z-index: 0;
    top: nth($form-input-checkbox-icon-shadow-properties, 3);
    left: nth($form-input-checkbox-icon-shadow-properties, 4);
    right: nth($form-input-checkbox-icon-shadow-properties, 5);
    bottom: nth($form-input-checkbox-icon-shadow-properties, 6);
    content: " ";
    position: absolute;
  }
}