$control-panel-width: $multiselect-common-height / 2;
$common-multiselect-element-distance: 10px;
$sort-control-panel-width: $gutter-size-md;

.multiselect {

  @include clearfix;

  position: relative;
  background: $color-gallery;
  border: 1px solid $color-alto;
  padding: $common-multiselect-element-distance;
  margin-bottom: $common-multiselect-element-distance;

  &__select {

    @include xs() {
      width: 100%;
      float: none;
    }

    width: 50%;
    float: left;

    &--available {

      @include xs() {
        padding-right: 0;
      }

      padding-right: $control-panel-width / 2;
    }

    &--selected {

      @include xs() {
        padding-left: 0;
      }

      padding-left: $control-panel-width / 2;
    }

    .form__input {
      margin-bottom: 0;
    }

    &--sortable {
      overflow: hidden;
      position: relative;
    }

  }

  &__sort-panel {

    @include transition(right .3s ease-in-out);

    bottom: 0;
    top: 34px;
    left: auto;
    display: block;
    position: absolute;
    width: $sort-control-panel-width;
    right: -$sort-control-panel-width;

    &-button {

      height: 50%;
      display: block;
      width: $sort-control-panel-width;

    }

    &--show {

      right: 0;

    }

  }

  &__button {

    @include interaction() {
      outline: 0;
    }

    @include xs() {
      width: 100%;
      height: auto;
    }

    width: 100%;
    padding: 0;
    float: left;
    height: 50%;
    color: $color-white;
    display: block;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    border: 1px solid transparent;

    /*TODO
    &--add {
      @extend .button--common;
    }

    &--remove {
      @extend .button--danger;
    }
*/
  }

  &__controls {

    @include clearfix;

    @include xs() {
      left: auto;
      width: 100%;
      bottom: auto;
      height: auto;
      margin-left: 0;
      position: relative;
      padding-top: $common-multiselect-element-distance;
      padding-bottom: $common-multiselect-element-distance;
    }

    bottom: $common-multiselect-element-distance;
    margin-left: $control-panel-width / -2;
    height: $multiselect-common-height;
    width: $control-panel-width;
    position: absolute;
    left: 50%;

  }
}