.media {
  @include clearfix;
  display: block;
  letter-spacing: -.215em;

  &__image,
  &__body {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: top;

    &--middle {
      vertical-align: top;
    }
  }

  &__image {
    max-width: percentage(1 / 4);

    &--right {
      float: right;
    }
  }

  &__body {
    max-width: percentage(3 / 4);
  }
}
