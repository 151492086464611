/*TODO:
.link {
  @include interaction-link(false) {
    color: $color-bay-of-many;
  }
  @include interaction-link(true) {
    color: $color-boston-blue;
  }
}*/

.link {
  text-decoration: underline;
}