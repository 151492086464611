$forms-rounded-control-height: 34px;
$forms-elegant-control-height: 32px;
$forms-base-control-height: 28px;
$forms-base-control-padding: 2px 6px;
$forms-help-padding: 2px;

$forms-validation-padding: 0 6px;
$forms-validation-margin: 8px 0;

$forms-control-border-width: 1px;

$forms-rounded-control-border-radius: 3px;

$forms-base-control-label-padding: 4px;

$form-input-checkbox-icon-shadow-properties: .08em .75em .16em .135em .135em .115em;

$forms-validation-error: 1px solid $color-guardsman-red;