@mixin generate-button-appearance($default-color, $interaction-color, $font-color, $interaction-font-color: $font-color) {

  @include interaction() {

    color: $interaction-font-color;
    background-color: $interaction-color;
  }

  color: $font-color;
  background-color: $default-color;
}


@mixin generate-gradient-button-appearance($base-gradient, $hover-gradient, $focus-color) {
  @include vertical-gradient(nth($base-gradient, 1), nth($base-gradient, 2));

  &:hover {
    @include vertical-gradient(nth($hover-gradient, 1), nth($hover-gradient, 2));
  }

  &:focus {
    background: $focus-color;
  }
}
