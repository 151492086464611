@mixin xs($modifier: only) {
  @if ($modifier == only) or ($modifier == max) {
    @media only screen and (max-width: $screen-xs-max) { @content; }
  } @else if ($modifier == min) {
    @content;
  }
}

@mixin sm($modifier: only) {
  @if ($modifier == only) {
    @media only screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) { @content; }
  } @else if ($modifier == max) {
    @media only screen and (max-width: $screen-sm-max) { @content; }
  } @else if ($modifier == min) {
    @media only screen and (min-width: $screen-sm) { @content; }
  }
}

@mixin md($modifier: only) {
  @if ($modifier == only) {
    @media only screen and (min-width: $screen-md) and (max-width: $screen-md-max) { @content; }
  } @else if ($modifier == max) {
    @media only screen and (max-width: $screen-md-max) { @content; }
  } @else if ($modifier == min) {
    @media only screen and (min-width: $screen-md) { @content; }
  }
}

@mixin lg($modifier: only) {
  @if ($modifier == only) or ($modifier == min) {
    @media only screen and (min-width: $screen-lg) { @content; }
  } @else if ($modifier == max) {
    @content;
  }
}

@mixin media($type, $modifier) {
  @if($type == xs) {
    @include xs($modifier) {
      @content;
    }
  } @else if($type == sm) {
    @include sm($modifier) {
      @content;
    }
  } @else if($type == md) {
    @include md($modifier) {
      @content;
    }
  } @else if($type == lg) {
    @include lg($modifier) {
      @content;
    }
  }
}