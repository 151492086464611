@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  animation: #{$str};
}