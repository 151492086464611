.chart {

  &__loader {
    display: none;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: -10px;
    padding: 20px;
    // TODO: color: $color-info;
    position: absolute;
    text-align: center;
    border: 1px solid $color-alto;
    background-color: $color-white-75;
    cursor: wait;

    &--icon {
      font-size: 32px;
    }
  }

  &__additional-info {

    display: none;

    &--show {
      display: block;
    }
  }

  &--pending {

    .chart__loader {

      display: block;
    }
  }
}