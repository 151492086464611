$icomoon-font-path: "fonts" !default;

$vi--FLOWPROCESS_INTERVIEW_CANCELED_BARE: "\e914";
$vi--FLOWPROCESS_INTERVIEW_ARRENGED_BARE: "\e919";
$vi--checkbox--checked: "\e834";
$vi--checkbox--blank: "\e835";
$vi--angle--left: "\e408";
$vi--angle--right: "\e409";
$vi--search: "\e906";
$vi--bell: "\e900";
$vi--envelope: "\e901";
$vi--person: "\e902";
$vi--smiley: "\e903";
$vi--exclamation--circle--outline: "\e904";
$vi--exclamation--circle: "\e905";
$vi--upload: "\e907";
$vi--refresh: "\e908";
$vi--POSITION_MODIFIED_BARE: "\e91d";
$vi--bars: "\e909";
$vi--location: "\e90a";
$vi--logout: "\e90b";
$vi--lock: "\e90c";
$vi--ban: "\e90d";
$vi--POSITION_DELETED_BARE: "\e91a";
$vi--chevron--down: "\e90e";
$vi--file--person: "\e90f";
$vi--check--circle: "\e910";
$vi--POSITION_APPLICATION_CONFIRMATION: "\e91b";
$vi--plus--circle: "\e911";
$vi--pen: "\e912";
$vi--share: "\e913";
$vi--question-mark--circle--outline: "\e915";
$vi--question-mark--circle: "\e916";
$vi--navigate--up: "\e917";
$vi--close: "\e918";
$vi--bin: "\e9ac";
$vi--FLOWPROCESS_CLOSED_AS_ACCEPTED_BARE: "\e973";
$vi--FLOWPROCESS_CLOSED_AS_REJECTED_BARE: "\e974";
$vi--sad: "\e9e5";
$vi--star: "\f005";
$vi--star--outline: "\f006";
$vi--circle--dot: "\f192";
$vi--circle--thin: "\f1db";

