@mixin create-spring-queries($spring-values) {
  @each $screen-query, $spring-min, $spring-max in $spring-values {
    @include media($screen-query, min) {
      min-width: $spring-min;
      max-width: $spring-max;
    }
  }
}

@mixin generate-springs($default-spring: $spring-base) {
  @include create-spring-queries($default-spring);

  @each $spring-query, $spring-values in $spring-sizes {
    &--#{$spring-query} {
      @include create-spring-queries($spring-values);
    }
  }
}