.breadcrumb {

  padding-top: 20px;

  &__list {

    @include clearfix();

    padding: 0;
    list-style: none;
    margin: 0 $breadcrumb-item-distance * -1;

    &-item {

      font-size: 1rem;
      margin: 0;
      float: left;
      padding: 0 $breadcrumb-item-distance;

      &--link {

        text-transform: uppercase;
        letter-spacing: $common-link-letter-spacing;

      }
    }
  }
}