.searchbar {

  &__background {
    &::before {
      @include vertical-gradient($color-searchbar-background-gradient-from, $color-searchbar-background-gradient-to);

      @include xs() { height: 330px; }
      @include sm(min) { height: 90px; }

      content: " ";
      position: absolute;
      top: -30px;
      bottom: auto;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

}