.page {
  padding-top: 0;
}

@include xs() {
  .page {
    &--docs {
      padding-top: 0;
    }
  }
}