.align {
  @each $screen in $screen-selectors {
    &--#{$screen} {
      @include media($screen, min) {
        &--left {
          text-align: left !important;
        }

        &--center {
          text-align: center !important;
        }

        &--right {
          text-align: right !important;
        }
      }
    }
  }

  &--left {
    text-align: left !important;
  }

  &--center {
    text-align: center !important;
  }

  &--right {
    text-align: right !important;
  }
}
