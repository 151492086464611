.hero {
  position: relative;
  color: $color-hero-text;

  &,
  &--md {
    height: $hero-height-md;
  }

  &--lg {
    height: $hero-height-lg;
  }

  &--sm {
    height: $hero-height-sm;
  }

  &--mantled {
    color: $color-hero-mantled-text;

    &,
    &-grey,
    &-white {
      &:before {
        content: " ";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
      }
    }

    &-white {
      color: inherit;

      &:before {
        background-color: $color-hero-mantle-white-transcluent;
      }
    }

    &-grey {
      color: inherit;

      &:before {
        background-color: $color-hero-mantle-grey-transcluent;
      }
    }

    &:before {
      background-color: $color-hero-mantle-transcluent;
    }
  }

  &__cover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;

    &--background-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__content {
    z-index: 2;
    height: 100%;
    position: relative;

    &-panel {
      position: relative;
      color: $color-hero-panel-text;
      background-color: $color-hero-panel-background;
      padding: $hero-content-panel-vertical-padding 0;

      &:before,
      &:after {
        content: " ";

        top: 0;
        bottom: 0;
        width: 100vw;
        height: 100%;
        display: block;
        position: absolute;
        background: $color-hero-panel-background;
      }

      &:before {
        left: auto;
        right: 100%;
      }

      &:after {
        left: 100%;
        right: auto;
      }

      &-table {
        left: auto;
        right: auto;
        width: 100%;
        display: table;
        position: absolute;
        top: $hero-content-panel-offset-md;
        bottom: $hero-content-panel-offset-md;
        height: $hero-height-md - (2 * $hero-content-panel-offset-md);

        &-cell {
          display: table-cell;
          vertical-align: middle;
        }

        & {
          @include when-inside(".hero--sm") {
            top: $hero-content-panel-offset-sm;
            bottom: $hero-content-panel-offset-sm;
            height: $hero-height-sm - (2 * $hero-content-panel-offset-sm);
          }

          @include when-inside(".hero--lg") {
            top: $hero-content-panel-offset-lg;
            bottom: $hero-content-panel-offset-lg;
            height: $hero-height-lg - (2 * $hero-content-panel-offset-lg);
          }
        }
      }

      &--inverted {
        display: inline-block;
        min-width: $hero-content-min-width-lg;
        color: $color-hero-panel-inverted-text;
        background-color: $color-hero-panel-inverted-background;
        border-top-right-radius: $hero-content-border-top-right-radius;

        &:after {
          content: none;
        }

        &:before {
          background: $color-white;
        }
      }

      &--clear {
        color: inherit;
        text-align: center;
        background-color: transparent;

        &:after,
        &:before {
          content: none;
        }
      }
    }
  }
}
