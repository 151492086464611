@mixin arrow($direction: "down", $width: 15px, $color: currentColor) {
  border: $width solid transparent;
  border-top-color: $color;
  display: block;
  height: 0;
  width: 0;

  @if $direction == "up" {
    transform: rotate(180deg);
  } @else if $direction == "right" {
    transform: rotate(270deg);
  } @else if $direction == "down" {
    transform: rotate(0deg);
  } @else if $direction == "left" {
    transform: rotate(90deg);
  } @else {
    @warn "#{$direction} not allowed";
  }
}
