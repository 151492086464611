$logitagger-selection-delete-button-size: $gutter-size-md;
$logitagger-selection-item-distance: $gutter-size-xs;

.logitagger {
  &__result-list {

    color: $color-mine-shaft;
    background-color: $color-white;
    border: 1px solid $color-alto;
    border-top: none;

    &-item {
      &--blank {
        color: $color-scarpa-flow;
        background-color: $color-gallery;
        padding: $gutter-size-xs / 2 $gutter-size-xs;
      }

      &-button {
        @include interaction() {
          background-color: $color-gallery;
        }
        padding: $gutter-size-xs / 2 $gutter-size-xs;
      }
    }
  }

  &__selection-list {

    color: $color-mine-shaft;
    padding: $gutter-size-xs 0;
    margin: ($logitagger-selection-item-distance / -2);

    &-item {

      @include interaction() {
        border: 1px solid $color-oslo-gray;

        .logitagger__selection-list-item-remove {

          @include interaction() {
            color: $color-mine-shaft;
          }

          color: $color-oslo-gray;
        }
      }

      @include transition(all .3s);

      line-height: $gutter-size-sm;
      border: 1px solid $color-alto;
      // TODO: background-color: $color-solitude;
      margin: ($logitagger-selection-item-distance / 2);
      padding: $gutter-size-xs ($gutter-size-xs + $logitagger-selection-delete-button-size) $gutter-size-xs $gutter-size-sm;

      &-remove {
        @include interaction() {
          border-left-color: $color-oslo-gray;
          background-color: $color-alto;
        }

        @include transition(all .3s);

        color: $color-alto;
        width: $logitagger-selection-delete-button-size;
      }

      &--expand {
        padding: 0;
      }
    }
  }

  &__selection-expand {
    @include transition(all .3s);

    @include interaction() {
      color: $color-mine-shaft;
    }

    padding: $gutter-size-xs;
    color: $color-alto;
  }

  &[disabled] {

    .logitagger__selection-list-item {

      padding: $gutter-size-xs $gutter-size-sm;

    }
  }
}