.heading {
  margin: $gutter-size-sm 0;

  &--1 {
    letter-spacing: .45em;
  }

  &--2 {
    letter-spacing: .45em;
  }

  &--3 {
    letter-spacing: .2em;
  }

  &--4 {
    letter-spacing: .45em;
  }

  &--5 {
    letter-spacing: .05em;
  }

  &--leading {
    margin-top: 0;
  }

  &--trailing {
    margin-bottom: 0;
  }

  &--no-spacing {
    letter-spacing: 0;
  }

  &--main-color {
    color: $color-primary;
  }

  &--info {
    color: $color-oslo-gray;
    letter-spacing: .1rem;
  }

  &--separator {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

@include sm() {
  .heading {
    margin: $gutter-size-sm 0;

    &--1 {
      letter-spacing: .45em;
    }

    &--2 {
      letter-spacing: .45em;
    }

    &--3 {
      letter-spacing: .2em;
    }

    &--4 {
      letter-spacing: .45em;
    }

    &--5 {
      letter-spacing: .05em;
    }

    &--leading {
      font-size: 130%;
      margin-top: 0;
    }

    &--trailing {
      margin-bottom: 0;
    }

    &--no-spacing {
      letter-spacing: 0;
    }

    &--main-color {
      color: $color-primary;
    }

    &--info {
      color: $color-oslo-gray;
      letter-spacing: .1rem;
    }
  }
}

@include xs() {
  .heading {
    margin: 0;

    &--1 {
      letter-spacing: .45em;
    }

    &--2 {
      letter-spacing: .45em;
    }

    &--3 {
      letter-spacing: .2em;
    }

    &--4 {
      letter-spacing: .45em;
    }

    &--5 {
      letter-spacing: .05em;
    }

    &--leading {
      font-size: 130%;
      margin-top: 0;
    }

    &--trailing {
      margin-bottom: 0;
    }

    &--no-spacing {
      letter-spacing: 0;
    }

    &--main-color {
      color: $color-primary;
    }

    &--info {
      color: $color-oslo-gray;
      letter-spacing: .1rem;
    }
  }
}