.footer-menu {

  color: $color-scarpa-flow;
  list-style-type: none;
  padding-left: 0;

  &__item {
    display: flex;
  }

  &__item-text {
    font-weight: bold;
    padding-bottom: 10px;
  }

  &__list-item {
    padding-right: 25px;
    margin: 0 auto;
  }

}