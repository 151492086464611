$discriminated-shadow: rgba(0, 0, 0, .75);

.position-card {
  display: block;
  overflow: hidden;
  letter-spacing: $position-card-letter-spacing;
  margin-bottom: $position-card-vertical-distance;
  border-top-right-radius: $position-card-border-radius;
  border-bottom-left-radius: $position-card-border-radius;
  height: 400px;
  width: 96%;

  &--discriminated {
    border: 3px solid $color-position-card-discriminated-border;
    box-shadow: 10px 10px 23px -10px $discriminated-shadow;
    webkit-box-shadow: 10px 10px 23px -10px $discriminated-shadow;
    moz-box-shadow: 10px 10px 23px -10px $discriminated-shadow;
  }

  &__header {

    @include box-shadow(0 0 $position-card-vertical-distance 0 $color-black-50);
    @include directional-gradient($color-position-card-header-gradient-from, $color-position-card-header-gradient-to, 135deg);

    display: block;
    border-radius: 0;
    position: relative;
    height: $position-card-header-height;
    padding: $position-card-header-padding;
    color: $color-position-card-header-text;

    &-position,
    &-location {
      display: block;
      margin: 0;
      padding: 0;
    }

    &-position {
      text-transform: uppercase;
      font-size: $position-card-font-size-big;
      line-height: $position-card-line-height;
    }

    &-location {
      font-size: $position-card-font-size-small;
    }
  }

  &__header--priority {
    @include directional-gradient($color-position-card-header-priority-gradient-from, $color-position-card-header-priority-gradient-to, 180deg);
  }

  &__content {

    @include interaction() {
      .position-card__content-slideup {
        bottom: 0;
        line-height: 1.25em;
      }
    }

    display: block;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: $position-card-content-height;

    &-slideup {
      @include xs() {
        transition: none;
        height: 100%;
        padding: 20px;
        bottom: 0;
        display: block;
        position: relative;
        color: $color-position-card-content-font;
        background-color: $color-position-card-content-transcluent;
      }

      @include transition(bottom .2s ease-in-out);

      height: 100%;
      padding: 20px;
      bottom: -100%;
      display: block;
      position: relative;
      color: $color-position-card-content-font;
      background-color: $color-position-card-content-transcluent;
    }
  }

  &__footer {

    @include box-shadow(0 0 $position-card-vertical-distance 0 $color-black-50);

    display: block;
    height: $position-card-footer-height;
    padding: $position-card-footer-padding;
    color: $color-position-card-footer-text;
    font-size: $position-card-font-size-small;
    background-color: $color-position-card-footer;

    &-more,
    &-company,
    &-favourite {
      margin: 0;
      padding: 0;
    }

    &-company {
      letter-spacing: 0;
      font-weight: $font-weight-bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-more {
      letter-spacing: 0;
      text-overflow: ellipsis;
      padding-right: 25px;
      white-space: nowrap;
      overflow: hidden;
      color: $color-position-card-footer-link;
      text-decoration: underline;
    }

    &-favourite {
      font-size: 25px;
      color: $color-position-card-star;
      position: absolute;
      left: auto;
      bottom: 0;
      right: 0;
      top: 0;
    }
  }
}