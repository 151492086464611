$caret-size: 12px;

.dropdown {
  position: relative;
  display: inline-block;

  &__toggle {
    outline: 0;
  }

  &__caret {

    @include animation("dropdownCaretAnimation 0.5s ease-in");

    width: 0;
    height: 0;
    top: 100%;
    display: none;
    position: absolute;
    margin-top: -$caret-size;
    margin-right: -$caret-size;
    border: $caret-size solid $color-white;
    background-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top: none;

    &--show {

      display: block;
    }
  }

  &__separator {

    border-top: none;
    margin: 0 $gutter-size-xs;
    background-color: transparent;
    border-color: $color-scarpa-flow;
  }

  &__menu {

    @include box-shadow(3px 6px 9px $color-black-20);

    top: 100%;
    padding: 0;
    left: auto;
    right: auto;
    bottom: auto;
    display: none;
    list-style: none;
    min-width: 256px;
    overflow-y: auto;
    text-align: left;
    max-height: 312px;
    position: absolute;
    background-color: $color-white;
    margin: 0;

    &--right {

      right: 0;
      left: auto;
    }

    &--left {

      left: 0;
      right: auto;
    }

    &-icon {

      width: 32px;
      font-size: 22px;
      vertical-align: sub;
    }

    &-item {
      margin: 0;
      padding: 0;
      font-size: 14px;

      &--action {

        @include interaction() {

          background-color: $color-gallery;
        }

        border: none;
        outline: 0;
        width: 100%;
        text-align: left;
        color: $color-mine-shaft;
        background-color: transparent;
        padding: $gutter-size-xs $gutter-size-sm;
      }
    }

    &--open {

      display: block;
    }
  }
}