.panel {
  @include sm(min) {
    padding: 0 32px;
  }

  @include md(min) {
    padding: 0 60px;
  }

  @include lg() {
    padding: 0 80px;
  }

  padding: 0;
}