.form {

  &__validation {

    &:not(&--display) {

      display: none;
    }
  }

  &__result {

    &:not(&--display) {
      display: none;
    }
  }

  &__loader {
    visibility: hidden;

    &--display {
      visibility: visible;
    }
  }

  &__row {

    position: relative;

    &--conditional {
      display: none;
    }

    &--display {
      display: block;
    }
  }

  &__element {
    overflow: hidden;

    &--conditional {
      @include opacity(0);

      height: 0;
      padding: 0;
      visibility: hidden;

      &:not(.form__element--display) {
        margin: 0;
      }
    }

    &--display {
      @include opacity(1);

      height: auto;
      overflow: visible;
      visibility: visible;
    }
  }

  &__control {

    &--hidden {
      @include opacity(0);

      position: absolute;
      z-index: -1;
    }

  }

}

.js-conditional {
  display: none;
}