.button {
  appearance: none;
  border: none;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 11px 37px;
  text-align: center;

  /*
  &--default {
    // TODO: @include generate-button-appearance($color-default, $color-default-dark, $color-mine-shaft, $color-mine-shaft, 18%);
  }

  &--common {
    // TODO: @include generate-button-appearance($color-common, $color-common-dark, $color-white);
  }

  &--success {
    // TODO: @include generate-button-appearance($color-success, $color-success-dark, $color-white);
  }

  &--info {
    // TODO: @include generate-button-appearance($color-info, $color-info-dark, $color-white);
  }

  &--warning {
    // TODO: @include generate-button-appearance($color-warning, $color-warning-dark, $color-white);
  }

  &--danger {
    // TODO: @include generate-button-appearance($color-danger, $color-danger-dark, $color-white);
  }
  */

  &--sm {
    font-size: 14px;
    padding: 7px 24px;
  }

  &--xs {
    font-size: 14px;
    padding: 4px 8px;
  }

  @include interaction() {
    cursor: pointer;
  }

  &--reset {
    border: none;
    padding: 0;
    background: none;
  }

  &--full-width {
    text-align: center;
    display: block;
    width: 100%;
  }

  &--disabled,
  &[disabled],
  &:disabled {
    &,
    &:hover,
    &:focus {
      background-color: $color-gallery;
      border-color: $color-gallery;
      color: $color-white;
      cursor: not-allowed;
    }

    &--dark {
      &,
      &:hover,
      &:focus {
        background-color: $color-alto;
        border-color: $color-alto;
        color: $color-mine-shaft;
        cursor: not-allowed;
      }
    }
  }

  &__panel {

    @include clearfix();

    @include xs() {
      width: 100%;
    }

    display: inline-block;

    &-item {

      float: left;

      + .button__panel-item {

        @include xs() {

          margin-left: 0;
        }

        margin-left: $gutter-size-sm;
      }
    }
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
  }
}

@include xs() {
  .button {
    width: 100%;
    display: block;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    padding: 11px 10px 12px;

    &--inline {
      width: auto;
      display: inline-block;
    }

    &--xs {
      padding: 8px 16px;
      margin-bottom: 8px;
    }
  }
}