%clearfix {
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin clearfix($extend: true) {
  @if $extend {
    @extend %clearfix;
  } @else {
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }
}
