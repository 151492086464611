.grid {

  @include clearfix;

  @include grid-gutters();

  margin: 0;
  height: 100%;
  display: block;
  padding-left: 0;
  list-style: none;
  position: relative;

  &--auto {
    height: auto;
  }

  &__item {

    @include grid-items();
    @include grid-item-offsets();
    @include grid-item-gutters();

    float: left;
    min-height: 1px;
    position: relative;

    &--pull {
      float: left;
    }

    &--push {
      float: right;
    }

    &-connector {

      @include xs() {
        line-height: 48px;
      }

      @include grid-item-connectors();

      top: 0;
      bottom: 0;
      z-index: 1;
      left: 100%;
      right: auto;
      display: block;
      font-size: 10px;
      line-height: 34px;
      text-align: center;
      position: absolute;
    }
  }
}