@include keyframes(fade-in) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(hide) {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(fade-zoom-out) {
  from {
    opacity: 0;
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(circle) {
  0% {
    transform: scale(.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@include keyframes(value) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(fade-in-extended) {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(flickering) {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(showSweetAlert) {
  0% {
    transform: scale(.7);
    -webkit-transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
    -webkit-transform: scale(.95);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@include keyframes(hideSweetAlert) {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(.5);
    -webkit-transform: scale(.5);
  }
}

@include keyframes(slideFromTop) {
  0% {
    top: 0;
  }

  100% {
    top: 50%;
  }
}

@include keyframes(slideToTop) {
  0% {
    top: 50%;
  }

  100% {
    top: 0;
  }
}

@include keyframes(slideFromBottom) {
  0% {
    top: 70%;
  }

  100% {
    top: 50%;
  }
}

@include keyframes(slideToBottom) {
  0% {
    top: 50%;
  }

  100% {
    top: 70%;
  }
}

@include keyframes(animateSuccessTip) {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@include keyframes(animateSuccessLong) {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@include keyframes(rotatePlaceholder) {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}

@include keyframes(animateErrorIcon) {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}

@include keyframes(dropdownCaretAnimation) {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(animateXMark) {
  0% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@include keyframes(rotate-loading) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
