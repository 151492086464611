@mixin grid-items($columns-count: $columns-count-default, $columns-widths-delimiter: $columns-widths-delimiter-default) {
  @each $media-selector in $screen-selectors {
    &--#{$media-selector} {
      @include media($media-selector, min) {
        @for $x from 1 through $columns-count {
          @for $y from 1 through $x {
            @if ($y != $x or $x == 1) and ($y % 2 != 0 or $x % 2 != 0) and ($y % 3 != 0 or $x % 3 != 0) and ($y % 5 != 0 or $x % 5 != 0) {
              &--#{$y}#{$columns-widths-delimiter}#{$x} {
                width: percentage($y / $x);
              }
            }
          }
        }
      }
    }
  }
}

@mixin grid-item-offsets($columns-count: $columns-count-default, $columns-widths-delimiter: $columns-widths-delimiter-default) {
  @each $media-selector in $screen-selectors {
    &--#{$media-selector} {
      @include media($media-selector, min) {
        @for $x from 1 through $columns-count {
          @for $y from 1 through $x {
            @if ($y != $x or $x == 1) and ($y % 2 != 0 or $x % 2 != 0) and ($y % 3 != 0 or $x % 3 != 0) and ($y % 5 != 0 or $x % 5 != 0) {
              &--offset-#{$y}#{$columns-widths-delimiter}#{$x} {
                margin-left: percentage($y / $x);
              }
            }
          }
        }

        &--offset-0 {
          margin-left: 0;
        }
      }
    }
  }
}