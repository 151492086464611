.spec-box {
  @include box-shadow(inset 0 (-1 * $spec-box-toggle-height) $spec-box-toggle-height (-1 * $spec-box-toggle-height) $color-black-20);

  border: 1px solid $color-black;
  padding: $spec-box-padding-horizontal $spec-box-padding-vertical;
  position: relative;

  &__heading {
    color: $color-primary;
    padding: $spec-box-heading-padding-top $spec-box-heading-padding-vertical $spec-box-heading-padding-bottom;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $spec-box-heading-font-size;
  }

  &__content {
    color: $color-oslo-gray;
    font-size: $spec-box-content-font-size;
    padding-bottom: $spec-box-content-padding-bottom;
    max-height: $spec-box-max-box-height;
    overflow: hidden;

    &--has-toggle {
      max-height: $spec-box-max-box-height - $spec-box-toggle-height;
    }

    &--expanded {
      max-height: none;
      height: auto;
    }

    &--full-size {
      height: $spec-box-max-box-height;
      padding: 0;
      margin: 0 (-1 * $spec-box-padding-vertical);
    }

    &--full-height {
      height: $spec-box-max-box-height;
    }
  }

  &__toggle {
    background: transparent;
    display: none;
    height: $spec-box-toggle-height;
    margin: 0 (-1 * $spec-box-padding-vertical);
    padding: $spec-box-toggle-padding-horizontal $spec-box-toggle-padding-vertical;
    color: $color-mine-shaft;
    font-size: $spec-box-toggle-font-size;
    font-weight: bold;
  }

   &__icon {
     vertical-align: middle;
   }
}