.response-status {

  padding: 4px;
  text-align: center;

  &__icon {

    padding: 1px;
  }

  &__code {

    &--number {
      font-size: 42px;
      font-weight: 900;
      display: inline-block;
      vertical-align: middle;
    }

    &--text {
      font-size: 24px;
      margin-left: 5px;
      font-weight: lighter;
      display: inline-block;
      vertical-align: middle;
    }
  }

/*  &--forbidden {
    // TODO: color: $color-danger;
  }

  &--not-found {
    // TODO: color: $color-danger;
  }

  &--internal-server-error {
    // TODO: color: $color-danger;
  }*/
}