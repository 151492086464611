.tooltip {
  z-index: $tooltip-z-index;

  &__content {
    border-radius: 5px;
    border: 1px solid $color-oslo-gray;
    background-color: $color-oslo-gray;
    color: $color-white;
    font-size: 14px;
  }

  &__arrow {
    background-color: transparent;
    border: 10px solid $color-oslo-gray;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
  }
}