@mixin generate-font-faces($fonts, $path: $font-path) {

  @each $font-name, $folder-name, $types in $fonts {

    @each $file-name, $type-name, $style, $weight in $types {

      $eot-url: "#{$path}/#{$folder-name}/eot/#{$file-name}.eot";
      $eot-iefix-url: "#{$path}/#{$folder-name}/eot/#{$file-name}.eot?#iefix";
      $woff-url: "#{$path}/#{$folder-name}/woff/#{$file-name}.woff";
      $ttf-url: "#{$path}/#{$folder-name}/ttf/#{$file-name}.ttf";
      $svg-url: "#{$path}/#{$folder-name}/svg/#{$file-name}.svg";

      @font-face {
        font-family: "#{$font-name}";
        src: url($eot-url);
        src: local("#{$font-name}"), url($eot-iefix-url) format("embedded-opentype"), url($woff-url) format("woff"), url($ttf-url) format("truetype"), url($svg-url) format("svg");
        font-weight: $weight;
        font-style: $style;
      }
    }
  }
}