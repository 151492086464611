.replicator {

  &__unit {
    & + & {
      margin-top: 10px;
    }
  }

  &__button {
    margin: 4px;

    &--multi {
      @include sm(min) {
        margin-top: 44px;
      }
    }

    &--add {
      color: $color-scarpa-flow;

      .vi {
        padding-right: 5px;
      }
    }

    &--remove {
      color: $color-oslo-gray;
      text-decoration: underline;
    }
  }

  &__item {
    &--multi {
      padding-bottom: 10px;
    }
  }

}