table {
  width: 100%;
}

caption {
  text-align: left;
}

th,
td {
  font-weight: $font-weight-regular;
  vertical-align: middle;
  text-align: left;
}