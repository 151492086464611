.button--common {
  /*TODO: FIX THIS (pagination needs this, Matyi knows)*/
  cursor: pointer;
}

.logigrid {

  padding: $logigrid-outer-padding;
  background-color: $logigrid-background-color;

  &__global-filter {

    padding: 0 0 $gutter-size-xs;

    &-input {

      display: inline-block;
      width: auto;

    }

    &-label {
      display: inline-block;
    }
  }

  &__length-select {

    font-size: $logigrid-length-select-font-size;
    color: $logigrid-length-select-color;
    padding: 0 0 $gutter-size-xs;

    &-input {

      display: inline-block;
      width: auto;

    }

  }

  &__info-display {

    padding: $gutter-size-xs 0;

  }

  &__pagination {

    background-color: $logigrid-pagination-page-background-color;

    &-list {

      &-item {

        border: 1px solid $logigrid-pagination-page-border-color;

      }

    }

    &-page {
      min-width: $logigrid-pagination-page-button-size;
      font-size: $logigrid-pagination-page-font-size;
      height: $logigrid-pagination-page-button-size;

      &--selected {

        @extend .button--common;

      }

      &:disabled {

        background-color: $logigrid-pagination-page-button-disabled-background-color;
        // TODO: color: $logigrid-pagination-page-button-disabled-color;

      }

    }

  }

  &__loading-indicator {

    background-color: $logigrid-loading-indicator-background-color;
    font-size: $logigrid-loading-indicator-font-size;
    // TODO: color: $logigrid-loading-indicator-color;

    &-icon {

      margin-top: $logigrid-loading-indicator-font-size / -2;

    }

  }

  &__filter {

    padding: $gutter-size-xs ($gutter-size-xs + $gutter-size-sm);
    // TODO: background-color: $logigrid-filter-background-color;
    color: $logigrid-filter-text-color;

    &-cell {

      border: 1px solid $logigrid-border-color;
      border-top: none;
      border-bottom: none;
    }

    &-number-input,
    &-string-input,
    &-date-input,
    &-datetime-input {

      display: inline-block;
      width: auto;

    }

    &--lov,
    &--boolean {

      .logigrid__filter-not-null-label {

        margin: $gutter-size-xs 0;

      }

    }

    &-label {
      display: inline-block;
    }
  }

  &__data {

    &-actions {

      margin: 0 ($gutter-size-xs / -2);

    }

    &-row {

      &:nth-child(odd),
      &:nth-child(even) {

        @include interaction() {

          background-color: $logigrid-data-row-hover-background-color;

        }

      }

      &:nth-child(odd) {

        background-color: $logigrid-data-row-odd-background-color;

      }

      &:nth-child(even) {

        background-color: $logigrid-data-row-even-background-color;

      }

    }

    &-column {

      border: 1px solid $logigrid-border-color;
      padding: $gutter-size-xs / 2 $gutter-size-xs;

    }

  }

  &__header {

    font-size: $gutter-size-sm;
    color: $logigrid-header-text-color;
    background-color: $logigrid-header-background-color;

    &-cell {

      font-weight: bold;
      border: 1px solid $logigrid-border-color;
      padding: $gutter-size-xs ($gutter-size-sm * 1.75);

    }

    &-filter {

      right: $gutter-size-xs / 2;

    }

    &-sort {

      left: $gutter-size-xs / 2;

    }

    &-sort,
    &-filter {

      top: $gutter-size-xs;
      bottom: $gutter-size-xs;

      &-button {

        @include transition(color .3s);

   /*     @include interaction() {
          // TODO: color: $logigrid-header-filter-interaction-color;
        }*/

        i {

          display: block;
          line-height: inherit;

        }
/*
        &--active {

          @include interaction(true) {
            // TODO: color: $logigrid-header-filter-active-color;
          }

        }*/

      }

    }

  }

  &__entry-count {

    font-size: $logigrid-entry-count-font-size;
    color: $logigrid-entry-count-color;

  }

}