.language {
  &__icon {
    display: inline-block;
    width: 20px;
    height: 10px;
    vertical-align: middle;
  }

  &__label {
    vertical-align: middle;
  }
}