.avatar {
  height: $profile-avatar-height;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 3px solid $color-profile-avatar-border;
  cursor: pointer;

  &__label {
    height: $profile-avatar-label-height;
    line-height: $profile-avatar-label-height;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $color-profile-avatar-mantle-white-transcluent;
    font-weight: bold;
    color: $color-profile-avatar-label-text;
  }
}