* {
  @include box-sizing();

  &:focus {
    outline: 0 solid transparent;
  }

  &:active {
    outline: 0;
  }
}
