.form {
  &__row {
    margin-bottom: 1.3rem;
    position: relative;
  }

  &__input {
    position: relative;

    &-icon {
      @include transition(color $transition-duration-default ease-in-out);

      text-align: center;
      position: absolute;
      width: $forms-base-control-height;

      &.form__input-icon--right {
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
      }

      &.form__input-icon--left {
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
      }

      &--base {
        line-height: $forms-base-control-height;
      }

      &--elegant {
        border: none;
        color: inherit;
        font-size: 1.25rem;
        text-align: center;
        background-color: transparent;
        width: $forms-elegant-control-height;
        line-height: $forms-elegant-control-height;
      }

      &--rounded {
        font-size: 1.1rem;
        width: $forms-rounded-control-height;
        line-height: $forms-rounded-control-height;
      }
    }
  }

  &__help {
    padding-left: $forms-help-padding;
    padding-right: $forms-help-padding;
    font-size: .8rem;

    &--left {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      left: auto;
      margin-right: 10px;

      .vi {
        cursor: help;
        line-height: $forms-rounded-control-height;
      }
    }

    &--right {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      right: auto;
      margin-left: 10px;

      .vi {
        line-height: $forms-rounded-control-height;
      }
    }

    &--base {

      .vi {
        line-height: $forms-base-control-height;
      }
    }

    &--warning {
      color: $color-form-help-warning;
    }

    &--success {
      color: $color-form-help-success;
    }
  }

  &__control {

    @include placeholder() {
      color: $color-oslo-gray;
      font-family: $primary-font-name;
      font-weight: $font-weight-regular;
    }
    @include transition(unquote("color #{$transition-duration-default} ease-in-out, border-color #{$transition-duration-default} ease-in-out, background-color #{$transition-duration-default} ease-in-out"));

    width: 100%;
    border: none;
    display: block;
    color: $color-mine-shaft;
    background-color: $color-white;

    &,
    &--base {
      @include border-radius(0);

      @include hover() {

        border-color: $color-oslo-gray;
      }

      @include focus() {

        border-color: $color-scarpa-flow;
      }

      color: $color-scarpa-flow;
      border: 1px solid $color-gallery;
      background-color: $color-gallery;
      height: $forms-base-control-height;
      padding: $forms-base-control-padding;

      &.form__control--iconed-right {
        padding-right: $forms-base-control-height;
      }

      &.form__control--iconed-left {
        padding-left: $forms-base-control-height;
      }
    }

    &--elegant {
      @include placeholder() {
        color: $color-form-control-elegant-placeholder;
      }

      @include hover() {
        color: $color-form-control-elegant-hover;
        border-color: $color-form-control-elegant-hover;

        & ~ .form__input-icon {
          color: $color-form-control-elegant-hover;
        }
      }

      @include focus() {
        border-color: $color-form-control-elegant-focus;
      }

      color: $color-form-control-elegant;
      border-color: $color-form-control-elegant;
      border: none;
      background: $color-form-control-elegant-background;
      height: $forms-elegant-control-height;
      border-bottom: 1px solid $color-form-control-elegant;

      &.form__control--iconed-right {
        padding-right: $forms-elegant-control-height;
      }

      &.form__control--iconed-left {
        padding-left: $forms-elegant-control-height;
      }

      & ~ .form__input-icon {
        color: $color-form-control-elegant;
      }
    }

    &--rounded {
      @include border-radius($forms-rounded-control-border-radius);

      @include hover() {

        border-color: $color-alto;
        background-color: $color-gallery;
      }

      @include focus() {

        background-color: $color-gallery;
        border-color: $color-scarpa-flow;
      }

      background-color: $color-white;
      height: $forms-rounded-control-height;
      border: $forms-control-border-width solid $color-oslo-gray;

      &.form__control--iconed-right {
        padding-right: $forms-rounded-control-height;
      }

      &.form__control--iconed-left {
        padding-left: $forms-rounded-control-height;
      }
    }

    &--checkbox {
      @include hover() {
        input[type=checkbox] {
          &:not(:checked) {
            & ~ .form__design--checkbox {
              i {
                @include generate-form-design-checkbox-icon-shadow();
              }
            }
          }
        }
      }

      display: inline-block;
      cursor: pointer;
      padding-left: 18px;
      color: $color-oslo-gray;

      .form__design--checkbox {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      input[type=checkbox] {
        &:not(:checked) {
          @include focus() {
            & ~ .form__design--checkbox {
              i {
                @include generate-form-design-checkbox-icon-shadow();

                color: $color-form-design-element-focus;
              }
            }
          }
        }

        &:checked {
          & ~ .form__design--checkbox {
            text-shadow: none;

            .vi--checkbox {
              &:before {
                content: $vi--checkbox--checked;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }

  &__validation {
    @include transition(unquote("visibility 0s ease, opacity 1.25s ease"));

    text-align: left !important;
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    border-width: 0;
    font-weight: bold;

    &--display {
      height: $forms-base-control-height;
      line-height: $forms-base-control-height;
      padding: $forms-validation-padding;
      visibility: visible;
      opacity: 1;
    }

    &--error {
      color: $color-form-validation-error;
      white-space: nowrap;

      &.form__validation--replicator-error {
        @include sm(min) {
          width: 65.2%;
        }
      }
    }

    &--has-error {

      .form__control {
        &,
        &--base,
        &--rounded {
          border: $forms-validation-error;
          background-color: $color-gallery;
        }

        &--elegant {
          border-bottom: $forms-validation-error;
        }
      }
    }
  }

  &__result {
    text-align: center;
    font-weight: bold;

    &--error {
      color: $color-form-validation-error;
    }

    &--display {
      opacity: 1;
    }
  }

  &__label {

    &--center {
      display: inline-block;
      padding-top: $forms-base-control-label-padding;
      padding-bottom: $forms-base-control-label-padding;
      line-height: $forms-base-control-height - (2 * $forms-base-control-label-padding);
    }

    &--side {
      &--base {
        display: inline-block;
        font-weight: $font-weight-bold;
        padding-top: $forms-base-control-label-padding;
        padding-bottom: $forms-base-control-label-padding;
        padding-right: $forms-base-control-label-padding;
        line-height: $forms-base-control-height - (2 * $forms-base-control-label-padding);
      }
    }
  }

  &__member-state {
    display: block;
    text-align: right;
    font-style: italic;
    font-weight: $font-weight-bold;
    padding-top: $forms-base-control-label-padding;
    padding-bottom: $forms-base-control-label-padding;
    line-height: $forms-base-control-height - (2 * $forms-base-control-label-padding);

    &--required {
      color: $color-form-member-state-required;
    }

    &--optional {
      color: $color-form-member-state-optional;
    }
  }

  &__saved-state {
    display: block;
    text-align: right;
    font-weight: $font-weight-bold;
    padding-top: $forms-base-control-label-padding;
    padding-bottom: $forms-base-control-label-padding;
    line-height: $forms-base-control-height - (2 * $forms-base-control-label-padding);
    color: $color-form-saved-state;
  }
}