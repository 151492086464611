.button {
  @include border-radius(3px);

  font-size: .875rem;
  letter-spacing: .1rem;
  text-transform: uppercase;

  &--primary {
    @include generate-gradient-button-appearance($gradient-primary, $gradient-primary-inverted, $color-primary);
  }

  &--secondary {
    @include generate-gradient-button-appearance($gradient-secondary, $gradient-secondary-inverted, $color-secondary);
  }

  &--tertiary {
    @include generate-gradient-button-appearance($gradient-tertiary, $gradient-tertiary-inverted, $color-tertiary);
  }

  &--file-upload {
    @include generate-gradient-button-appearance($gradient-file-upload, $gradient-file-upload-inverted, $color-alto);
    border: 1px solid $color-button-file-upload-border;
    color: $color-button-file-upload-text;
  }

  &--angular {
    @include border-radius(0);
  }

  &--no-wrap {
    @include overflow-nowrap();
  }

  &--sm {
    padding: 6px 12px;
    line-height: 1rem;
    font-size: .75rem;
    text-transform: none;
    letter-spacing: .05rem;
  }

  &--transparent {
    background-color: transparent;
    color: $color-black;
    text-transform: none;
    padding: 10px;

    &:disabled {
      color: $color-alto;
      background-color: transparent;
    }
  }

  &--warning {
    @include generate-button-appearance($color-warning, $color-warning-dark, $color-white);
  }

  &--success {
    @include generate-button-appearance($color-success, $color-success-dark, $color-white);
  }

}