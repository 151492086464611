body {
  &.body {

    .datepicker {

      &.dropdown-menu {
        @include box-shadow(none);

        border-radius: 0;
        color: $color-mine-shaft;
        border-color: $color-oslo-gray;
      }

      &-dropdown {
        &.datepicker-orient-top:before {
          border-top-color: $color-oslo-gray;
        }

        &.datepicker-orient-bottom:before {
          border-bottom-color: $color-oslo-gray;
        }
      }

      table {

        tr {

          td,
          th {

            &.new,
            &.old {
              color: $color-alto;
            }

            border-radius: 0;

            &.focused {

              @include interaction() {
                background: $color-alto;
              }

              background: $color-gallery;
            }

            &.active,
            &.active.focused {

            /*  @include interaction() {
                background: $color-st-topaz;
              }*/

              color: $color-white;
              // TODO: background: $color-boston-blue;
            }

            .day {
              @include interaction() {
                background: $color-gallery;
              }
            }

            span {

              border-radius: 0;

              &.active,
              &.active.focused {

                /*@include interaction() {
                  background: $color-st-topaz;
                }
*/
                color: $color-white;
                // TODO: background: $color-boston-blue;
              }

              &.focused {

                @include interaction() {
                  background: $color-alto;
                }

                background: $color-gallery;
              }
            }
          }
        }
      }
    }
  }
}